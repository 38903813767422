import '../../App.scss';
import React from 'react';
import Banner from './main-banner/Banner.js';
// import Navbar from './header/Navbar';
import Footer from './footer/Footer.js';

function Landing() {

  return (
    <>
      {/* <Navbar /> */}
      <Banner />
      <Footer />
    </>
  );
}

export default Landing;