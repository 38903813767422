let Environment = {
  // api_url: "http://192.168.20.70:8080",
  // api_url: "https://wizardapi.quecko.com",
  // api_url: "http://ec2-54-191-120-95.us-west-2.compute.amazonaws.com:8080",
  // marketPlaceContract: "0x161975F0f31e935eD9f622A43cdcbF7b0FC216a3",
  StackContact: "0x03B830791ebb7c70C47936A57cDf02D13ff41168",
  // launchPadContract: "0xFc1F0Be40C59aBa1124532f4ceDE63Ff5CB84f1F",
  // nftLaunchpad: '0x5D22b2400cF591a6A08D8EBa5D69f871D1DeD367'
  ClamingContract: "0x3A6daD990fB68571dbEA87817fbd1127Ef681Ca3",
  // nftLaunchpad: '0xd8F75Dd989Ab32420155bce4804e5af0cA41F895',
  Doptoken: "0x3010ccb5419F1EF26D40a7cd3F0d707a0fa127Dc",
  StackGraph:
    "https://api.studio.thegraph.com/query/79148/gemsstaking/version/latest",
};

export default Environment;
