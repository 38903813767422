import React, { useState, useEffect } from "react";
import "./datatable.scss";
import { Link } from "react-router-dom";
import { Accordion, Pagination } from "react-bootstrap"
import { parseInt } from "lodash";

const StakingTable = ({ setCurrentPage, currentPage, pageSize, indexwaits, activeDepositCount, activeDeposit, account, getDepositData }) => {

  // console.log("data here", activeDepositCount)
  // Function to convert timestamp to date and time
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    return date?.toLocaleString('en-GB', options).split(', ');
  };
  useEffect(() => {
    if (account && currentPage === 1 || indexwaits === 'MYSTAKING') {
      getDepositData(currentPage, pageSize)
    } else if (account || indexwaits === 'MYSTAKING') {
      getDepositData(currentPage, pageSize)
    }
    // getDepositData(currentPage, pageSize);
  }, [account, currentPage, indexwaits]);


  const totalPages = Math.ceil(activeDepositCount / pageSize);

  return (
    <>
      <div className="mytable">
        <section className="datatable">
          <div className="bgtable">
            <div className="table-responsive" style={{ position: "relative" }}>
              <table class="table">
                <thead>
                  <tr>
                    <th>
                      <div
                        className="parent-tag"
                      >
                        Date
                        <div
                          className="filter-btn"
                          style={{ cursor: "pointer" }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
                            <path d="M7.90908 5.75488H4.09497H0.660442C0.072714 5.75488 -0.22115 4.97429 0.195157 4.51669L3.36644 1.03092C3.87458 0.472393 4.70107 0.472393 5.20921 1.03092L6.41528 2.35659L8.38049 4.51669C8.79068 4.97429 8.49681 5.75488 7.90908 5.75488Z" fill="#939FA3" />
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
                            <path d="M7.90908 0.469727H4.09497H0.660442C0.072714 0.469727 -0.22115 1.25032 0.195157 1.70792L3.36644 5.19369C3.87458 5.75222 4.70107 5.75222 5.20921 5.19369L6.41528 3.86802L8.38049 1.70792C8.79068 1.25032 8.49681 0.469727 7.90908 0.469727Z" fill="#939FA3" />
                          </svg>
                        </div>
                      </div>
                    </th>
                    <th>
                      <div
                        className="parent-tag"
                      >
                        Activity
                      </div>
                    </th>
                    <th>
                      <div
                        className="parent-tag"
                      >
                        AMOUNT STAKED

                      </div>
                    </th>

                    <th>
                      <div className="parent-tag">
                        TX Hash
                        <div
                          className="filter-btn"
                          style={{ cursor: "pointer" }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
                            <path d="M7.90908 5.75488H4.09497H0.660442C0.072714 5.75488 -0.22115 4.97429 0.195157 4.51669L3.36644 1.03092C3.87458 0.472393 4.70107 0.472393 5.20921 1.03092L6.41528 2.35659L8.38049 4.51669C8.79068 4.97429 8.49681 5.75488 7.90908 5.75488Z" fill="#939FA3" />
                          </svg>
                          <svg xmlns="http://www.w3.org/2000/svg" width="9" height="6" viewBox="0 0 9 6" fill="none">
                            <path d="M7.90908 0.469727H4.09497H0.660442C0.072714 0.469727 -0.22115 1.25032 0.195157 1.70792L3.36644 5.19369C3.87458 5.75222 4.70107 5.75222 5.20921 5.19369L6.41528 3.86802L8.38049 1.70792C8.79068 1.25032 8.49681 0.469727 7.90908 0.469727Z" fill="#939FA3" />
                          </svg>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {activeDeposit?.map((deposit, index) => {
                    // console.log("time", deposit)
                    const [date, time] = formatTimestamp(deposit?.blockTimestamp);
                    return (
                      <tr key={index}>
                        <td>
                          <div className="stakingdatesmain">
                            <h6 className="datepara">    {new Date(
                              deposit?.blockTimestamp * 1000
                            ).toLocaleDateString()}</h6>
                            <p className="timepara">   {new Date(
                              deposit?.blockTimestamp * 1000
                            ).toLocaleTimeString()}</p>
                          </div>
                        </td>
                        <td>Staked</td>
                        <td>{(deposit?.amount / 1e18).toLocaleString()} GEMS</td>
                        <td>
                          <a href={`https://etherscan.io/tx/${deposit?.transactionHash}`} target="_blank" className="hashpara">
                            {deposit.transactionHash.slice(0, 6)}...{deposit.transactionHash.slice(-4)}
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none" className="hide-in-light-theme">
                          <path d="M7.58301 7.02943L12.3663 2.24609" stroke="#E1EFF5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M12.8332 4.5793V1.7793H10.0332" stroke="#E1EFF5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M6.41699 1.7793H5.25033C2.33366 1.7793 1.16699 2.94596 1.16699 5.86263V9.36263C1.16699 12.2793 2.33366 13.446 5.25033 13.446H8.75033C11.667 13.446 12.8337 12.2793 12.8337 9.36263V8.19596" stroke="#E1EFF5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none" className="d-none show-in-light-theme">
                          <path d="M7.58301 6.45521L12.3663 1.67188" stroke="#02171D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M12.8332 4.00508V1.20508H10.0332" stroke="#02171D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M6.41699 1.20508H5.25033C2.33366 1.20508 1.16699 2.37174 1.16699 5.28841V8.78841C1.16699 11.7051 2.33366 12.8717 5.25033 12.8717H8.75033C11.667 12.8717 12.8337 11.7051 12.8337 8.78841V7.62175" stroke="#02171D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {
              activeDepositCount > 0 &&
              (
                <div className="footer-content">

                  <div className="left-f">
                    <h6>
                      SHOWING {currentPage * pageSize - pageSize + 1}-
                      {Math.min(currentPage * pageSize, activeDepositCount)} OF {activeDepositCount}
                    </h6>
                  </div>
                  <div className="right-f">
                    <Pagination>
                      <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M7.38867 1.93555L4.88727 1.93555L1.49422 7.99682L3.99563 7.99682L7.38867 1.93555Z" fill="#939FA3" />
                        <path d="M7.38867 14.0703L4.88727 14.0703L1.49422 8.00904L3.07071 6.96117L3.99563 8.00904L7.38867 14.0703Z" fill="#939FA3" />
                        <path d="M7.51594 6.85742H14.461L13.1863 9.14313H6.24121L7.51594 6.85742Z" fill="#939FA3" />
                      </svg>
                    </Pagination.Prev>
                      {[...Array(Math.ceil(activeDepositCount / pageSize)).keys()].map(number => (
                        <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => setCurrentPage(number + 1)}>
                          {number + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M8.61133 1.93555L11.1127 1.93555L14.5058 7.99682L12.0044 7.99682L8.61133 1.93555Z" fill="#2BF7C4" fill-opacity="0.8" />
                        <path d="M8.61133 14.0703L11.1127 14.0703L14.5058 8.00904L12.9293 6.96117L12.0044 8.00904L8.61133 14.0703Z" fill="#2BF7C4" fill-opacity="0.8" />
                        <path d="M2.81379 6.85742H9.75884L8.48412 9.14313H1.53906L2.81379 6.85742Z" fill="#2BF7C4" fill-opacity="0.8" />
                      </svg>
                    </Pagination.Next>
                    </Pagination>
                  </div>
                </div>
              )
            }

          </div>
        </section>
      </div>

      <div className="mytablemobile d-none">
        <div className="mainupperr">
          {activeDeposit?.map((deposit, index) => {
            const [date] = formatTimestamp(deposit?.claimTime);
            return (
              <div className="mbltable">
                <div className="mbltableinner">
                  <div className="mbltabledetail">
                    <p className="mblpara">DATE</p>
                    <h6 className="mblhead"> {new Date(
                      deposit?.blockTimestamp * 1000
                    ).toLocaleDateString()}</h6>
                  </div>
                  <div className="mbltabledetail">
                    <p className="mblpara">TX HASH</p>
                    <a href={`https://etherscan.io/tx/${deposit?.transactionHash}`} className="mblheadlink">
                      {deposit.transactionHash.slice(0, 6)}...{deposit.transactionHash.slice(-4)}
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none" className="hide-in-light-theme">
                          <path d="M7.58301 7.02943L12.3663 2.24609" stroke="#E1EFF5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M12.8332 4.5793V1.7793H10.0332" stroke="#E1EFF5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M6.41699 1.7793H5.25033C2.33366 1.7793 1.16699 2.94596 1.16699 5.86263V9.36263C1.16699 12.2793 2.33366 13.446 5.25033 13.446H8.75033C11.667 13.446 12.8337 12.2793 12.8337 9.36263V8.19596" stroke="#E1EFF5" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15" fill="none" className="d-none show-in-light-theme">
                          <path d="M7.58301 6.45521L12.3663 1.67188" stroke="#02171D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M12.8332 4.00508V1.20508H10.0332" stroke="#02171D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                          <path d="M6.41699 1.20508H5.25033C2.33366 1.20508 1.16699 2.37174 1.16699 5.28841V8.78841C1.16699 11.7051 2.33366 12.8717 5.25033 12.8717H8.75033C11.667 12.8717 12.8337 11.7051 12.8337 8.78841V7.62175" stroke="#02171D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </a>
                  </div>
                </div>
                <div className="mbltableinner">
                  <div className="mbltabledetail">
                    <p className="mblpara">ACTIVITY</p>
                    <h6 className="mblhead">Staked</h6>
                  </div>
                  <div className="mbltabledetail">
                    <p className="mblpara">AMOUNT STAKED</p>
                    <p className="mblhead">{(deposit?.amount / 1e18).toLocaleString()} GEMS</p>
                  </div>
                </div>
              </div>
            )
          })}

          {
            activeDepositCount > 0 &&
            (
              <div className="footer-content">
                {/* <div className="left-f">
                  <h6>
                    SHOWING {currentPage * pageSize - pageSize + 1}-
                    {Math.min(currentPage * pageSize, activeDepositCount)} OF {activeDepositCount}
                  </h6>
                </div> */}
                <div className="right-f">
                  <Pagination>
                    <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M7.38867 1.93555L4.88727 1.93555L1.49422 7.99682L3.99563 7.99682L7.38867 1.93555Z" fill="#939FA3" />
                        <path d="M7.38867 14.0703L4.88727 14.0703L1.49422 8.00904L3.07071 6.96117L3.99563 8.00904L7.38867 14.0703Z" fill="#939FA3" />
                        <path d="M7.51594 6.85742H14.461L13.1863 9.14313H6.24121L7.51594 6.85742Z" fill="#939FA3" />
                      </svg>
                    </Pagination.Prev>
                    {[...Array(Math.ceil(activeDepositCount / pageSize)).keys()].map(number => (
                      <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => setCurrentPage(number + 1)}>
                        {number + 1}
                      </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M8.61133 1.93555L11.1127 1.93555L14.5058 7.99682L12.0044 7.99682L8.61133 1.93555Z" fill="#2BF7C4" fill-opacity="0.8" />
                        <path d="M8.61133 14.0703L11.1127 14.0703L14.5058 8.00904L12.9293 6.96117L12.0044 8.00904L8.61133 14.0703Z" fill="#2BF7C4" fill-opacity="0.8" />
                        <path d="M2.81379 6.85742H9.75884L8.48412 9.14313H1.53906L2.81379 6.85742Z" fill="#2BF7C4" fill-opacity="0.8" />
                      </svg>
                    </Pagination.Next>
                  </Pagination>


                </div>
                {/* <div className="right-f">
                <Pagination>
                    <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} />
                    {[...Array(15).keys()].map(number => (
                        <Pagination.Item key={number + 1} active={number + 1 === currentPage} onClick={() => setCurrentPage(number + 1)}>
                            {number + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next onClick={() => setCurrentPage(prev => prev + 1)} />
                </Pagination>
            </div> */}
              </div>
            )
          }

        </div>

      </div>
    </>
  );
};

export default StakingTable;
