import { Link } from "react-router-dom";
import "./navbar.scss";
import { useState, useEffect } from "react";
import useAuth from '../../../hooks/useAuth';
// import Environment from '@/utils/Enviroment';
import axios from 'axios';
import { toast } from "react-toastify";
import { setupNetwork } from "../../../utils/wallet";

import Modal from 'react-bootstrap/Modal';
import { useWeb3React } from '@web3-react/core';
import useWeb3 from '../../../hooks/useWeb3';
import { Offcanvas } from "react-bootstrap";



const Navbar = ({ handleShow, show, setShow }) => {
  let { account, chainId } = useWeb3React();
  const web3 = useWeb3();
  // console.log("account here is", account, chainId)

  const { login, logout } = useAuth();
  const handleClose = () => setShow(false);

  const connectWallet = async (e) => {
    if (account) {
      const connectorId = window.localStorage.getItem("connectorId")
      await logout(connectorId);
      localStorage.removeItem("connectorId");
      localStorage.removeItem("flag");
    } else {
      await login("injected", e);
      localStorage.setItem("connectorId", "injected");
      localStorage.setItem("flag", "true");
      localStorage.setItem("chain", e);
      handleClose();
      // setclickedbtn(false)
      // setLoader(false);
    }
    // setLoader(false);
  };

  const trustWallet = async (e) => {
    handleClose();
    try {
      if (account) {
        await logout("walletconnect");
      } else {
        login("walletconnect", e);
        localStorage.setItem('connectorId', 'walletconnect');
        localStorage.setItem("flag", "true");
      }
    } catch (error) {
      console.error('Error during WalletConnect operation:', error);
      toast.error('An error occurred during WalletConnect operation');
    }
  };

  const disconnectWallet = async () => {
    const connectorId = window.localStorage.getItem("connectorId")
    logout(connectorId)
    localStorage.removeItem('connectorId')
    localStorage.removeItem('flag')
    localStorage.removeItem('chain')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('wallet')
  };


  useEffect(() => {
    // Update the timer every second
    if (chainId != 1) {
      setupNetwork(1)
    }
  }, [chainId]);


  const [showoff, setShowoff] = useState(false);

  const handleCloseoff = () => setShowoff(false);
  const handleShowoff = () => setShowoff(true);


  const [show111, setShow111] = useState(false);
  const handleClose111 = () => setShow111(false);
  const handleShow111 = () => setShow111(true);


  const [theme, setTheme] = useState(localStorage.getItem("theme") ? localStorage.getItem("theme") : "light-theme");
const toggleTheme = () => {
  if (theme === "light-theme") {
    setTheme("dark-theme");
    localStorage.setItem("theme", "dark-theme");
  } else {
    setTheme('light-theme');
    localStorage.setItem("theme", "light-theme");

  }
}
useEffect(() => {
  document.body.className = theme;
}, [theme]);



  return (
    <>
 
      <section className="main-navbar">
        <div className="custom-container">
          <nav class="navbar navbar-expand-xl">
            <Link class="navbar-brand" to="/">
            <img src="\logo.svg" alt="img" className="img-fluid statcardimg hide-in-light-theme" />
            <img src="\blacklogo.svg" alt="img" className="img-fluid d-none show-in-light-theme" />
            </Link>
            <div className="twice-btns">
              <a onClick={handleShow111} className="howitworks-btn">how it works?</a>
              {/* <Link to="/faqs" className="faqs-btn">FAQs</Link> */}
              <div className="toggle-container" style={theme === "light-theme" ? { background: "#0C2B33" } : {}}>
                  <input
                    type="checkbox"
                    id="theme-toggle"
                    className="theme-toggle"
                    onChange={toggleTheme}
                    checked={theme === "dark-theme"}
                  />
                  <label htmlFor="theme-toggle" className="toggle-label">
                    <span className="moon">
                      {
                        theme == 'light-theme' ? <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                          <mask id="path-1-inside-1_184_3720" fill="white">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M4.7596 0.743164C3.15377 1.19669 1.78276 2.39171 1.17307 4.08098C0.168832 6.86342 1.61035 9.93313 4.3928 10.9374C7.08598 11.9094 10.0483 10.59 11.1462 7.98123C10.1071 8.27472 8.96956 8.25769 7.87639 7.86315C5.09395 6.85891 3.65243 3.78921 4.65666 1.00676C4.68887 0.917505 4.72322 0.829626 4.7596 0.743164Z" />
                          </mask>
                          <path d="M1.17307 4.08098L-0.100904 3.62118L1.17307 4.08098ZM4.7596 0.743164L6.00796 1.26854L7.0995 -1.32506L4.39149 -0.560256L4.7596 0.743164ZM4.3928 10.9374L4.8526 9.66339H4.85259L4.3928 10.9374ZM11.1462 7.98123L12.3946 8.50662L13.4861 5.91301L10.7781 6.67781L11.1462 7.98123ZM7.87639 7.86315L8.33619 6.58918L8.33619 6.58918L7.87639 7.86315ZM4.65666 1.00676L3.38269 0.546964L3.38269 0.546965L4.65666 1.00676ZM2.44704 4.54078C2.9022 3.27966 3.92413 2.38651 5.12772 2.04658L4.39149 -0.560256C2.38341 0.00686955 0.663316 1.50375 -0.100904 3.62118L2.44704 4.54078ZM4.85259 9.66339C2.77375 8.9131 1.69674 6.61963 2.44704 4.54078L-0.100904 3.62118C-1.35908 7.10722 0.446962 10.9532 3.933 12.2113L4.85259 9.66339ZM9.89788 7.45585C9.07802 9.40392 6.86436 10.3895 4.8526 9.66339L3.933 12.2113C7.3076 13.4293 11.0186 11.7762 12.3946 8.50662L9.89788 7.45585ZM10.7781 6.67781C10.0022 6.89695 9.15454 6.88453 8.33619 6.58918L7.41659 9.13712C8.78457 9.63084 10.2119 9.65248 11.5143 9.28466L10.7781 6.67781ZM8.33619 6.58918C6.25734 5.83888 5.18034 3.54541 5.93063 1.46656L3.38269 0.546965C2.12452 4.033 3.93056 7.87894 7.41659 9.13712L8.33619 6.58918ZM5.93063 1.46656C5.95487 1.3994 5.98067 1.33338 6.00796 1.26854L3.51125 0.217784C3.46576 0.325869 3.42288 0.435615 3.38269 0.546964L5.93063 1.46656Z" fill="#D1DBDF" mask="url(#path-1-inside-1_184_3720)" />
                        </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                          <mask id="path-1-inside-1_184_3699" fill="white">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M4.7596 0.743164C3.15377 1.19669 1.78276 2.39171 1.17307 4.08098C0.168832 6.86342 1.61035 9.93313 4.3928 10.9374C7.08598 11.9094 10.0483 10.59 11.1462 7.98123C10.1071 8.27472 8.96956 8.25769 7.87639 7.86315C5.09395 6.85891 3.65243 3.78921 4.65666 1.00676C4.68887 0.917505 4.72322 0.829626 4.7596 0.743164Z"
                            />
                          </mask>
                          <path
                            d="M1.17307 4.08098L-0.100904 3.62118L1.17307 4.08098ZM4.7596 0.743164L6.00796 1.26854L7.0995 -1.32506L4.39149 -0.560256L4.7596 0.743164ZM4.3928 10.9374L4.8526 9.66339H4.85259L4.3928 10.9374ZM11.1462 7.98123L12.3946 8.50662L13.4861 5.91301L10.7781 6.67781L11.1462 7.98123ZM7.87639 7.86315L8.33619 6.58918L8.33619 6.58918L7.87639 7.86315ZM4.65666 1.00676L3.38269 0.546964L3.38269 0.546965L4.65666 1.00676ZM2.44704 4.54078C2.9022 3.27966 3.92413 2.38651 5.12772 2.04658L4.39149 -0.560256C2.38341 0.00686955 0.663316 1.50375 -0.100904 3.62118L2.44704 4.54078ZM4.85259 9.66339C2.77375 8.9131 1.69674 6.61963 2.44704 4.54078L-0.100904 3.62118C-1.35908 7.10722 0.446962 10.9532 3.933 12.2113L4.85259 9.66339ZM9.89788 7.45585C9.07802 9.40392 6.86436 10.3895 4.8526 9.66339L3.933 12.2113C7.3076 13.4293 11.0186 11.7762 12.3946 8.50662L9.89788 7.45585ZM10.7781 6.67781C10.0022 6.89695 9.15454 6.88453 8.33619 6.58918L7.41659 9.13712C8.78457 9.63084 10.2119 9.65248 11.5143 9.28466L10.7781 6.67781ZM8.33619 6.58918C6.25734 5.83888 5.18034 3.54541 5.93063 1.46656L3.38269 0.546965C2.12452 4.033 3.93056 7.87894 7.41659 9.13712L8.33619 6.58918ZM5.93063 1.46656C5.95487 1.3994 5.98067 1.33338 6.00796 1.26854L3.51125 0.217784C3.46576 0.325869 3.42288 0.435615 3.38269 0.546964L5.93063 1.46656Z"
                            fill="#D1DBDF"
                            mask="url(#path-1-inside-1_184_3699)"
                          />
                        </svg>
                      }

                    </span>
                    <span className="sun" style={theme === "light-theme" ? { background: "#D1DBDF" } : {}}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <circle cx="5.91193" cy="6.01862" r="2.06791" stroke="#02171D" strokeWidth="1.43218" />
                        <path d="M6.82129 10.136L6.84222 11.8167L5.17835 11.8374L5.15742 10.1567L6.82129 10.136Z" fill="#02171D" />
                        <path d="M6.82129 0.162598L6.84222 1.84332L5.17835 1.86405L5.15742 0.183323L6.82129 0.162598Z" fill="#02171D" />
                        <path d="M1.86377 6.82129L0.183045 6.84222L0.16232 5.17835L1.84304 5.15742L1.86377 6.82129Z" fill="#02171D" />
                        <path d="M11.8374 6.82129L10.1567 6.84222L10.136 5.17835L11.8167 5.15742L11.8374 6.82129Z" fill="#02171D" />
                        <path d="M2.49463 3.65649L1.29137 2.48285L2.45325 1.29166L3.65651 2.4653L2.49463 3.65649Z" fill="#02171D" />
                        <path d="M9.54639 10.7083L8.34313 9.5346L9.50501 8.34341L10.7083 9.51706L9.54639 10.7083Z" fill="#02171D" />
                        <path d="M8.34326 2.49463L9.51691 1.29137L10.7081 2.45325L9.53445 3.65651L8.34326 2.49463Z" fill="#02171D" />
                        <path d="M1.29199 9.54663L2.46564 8.34338L3.65683 9.50526L2.48318 10.7085L1.29199 9.54663Z" fill="#02171D" />
                      </svg>
                    </span>
                    <span className="toggle-slider"></span>
                  </label>
                </div>
              {account ?
                (
                  <button onClick={disconnectWallet} className="btn-disconnect">
                    {account?.slice(0, 8)}...
                    {account?.slice(
                      account?.length - 3,
                      account?.length
                    )}
                  </button>
                )
                :
                (
                  <button onClick={handleShow} className="btn-launch">
                    Connect Wallet
                  </button>
                )
              }
              
            </div>
            <a onClick={handleShowoff} className="bar-btn d-none"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M3 7H21" stroke="white" stroke-width="1.5" stroke-linecap="round" />
              <path d="M3 12H21" stroke="white" stroke-width="1.5" stroke-linecap="round" />
              <path d="M3 17H21" stroke="white" stroke-width="1.5" stroke-linecap="round" />
            </svg></a>


          </nav>
          
        </div>
      </section>
      <Modal className="generic-modal walletconectmodal" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>Connect Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="walletmains" style={theme === "light-theme" ? { gap: "15px" } : {}}>
            <div className="walletinner sddsdsdsdsdsds" onClick={connectWallet} >
              <img src="\assets\metamaskimg.svg" alt="walletimg" className="walletimg" />
              <p className="walletpara">Metamask</p>
            </div>
            <div className="walletinner" onClick={() => {
              trustWallet();
              setShowoff(false);
            }}>
              <img src="\assets\walletconnectimg.svg" alt="walletimg" className="walletimg" />
              <p className="walletpara">WalletConnect</p>
            </div>
          </div>
          {/* <button className="commonbtn">OKAY</button>
          <div className="mainbtns">
          <button className="dullbtn">Cancel</button>
          <button className="brdrbtn">Confirm</button>
          </div> */}
        </Modal.Body>
      </Modal>


      <Offcanvas className="offcanvas-bar" show={showoff} onHide={handleCloseoff}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title> <a href="/">    <img src="\logo.svg" alt="img" className="img-fluid statcardimg hide-in-light-theme" />
          <img src="\blacklogo.svg" alt="img" className="img-fluid d-none show-in-light-theme" /></a> </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="multi-btns">
            <a onClick={handleShow111} className="faqs-btn">how it works?</a>
            {/* <Link to="/faqs" className="faqs-btn">FAQs</Link> */}
            {account ?
              (
                <button onClick={disconnectWallet} className="btn-disconnect">
                  {account?.slice(0, 8)}...
                  {account?.slice(
                    account?.length - 3,
                    account?.length
                  )}
                </button>
              )
              :
              (
                <button onClick={handleShow} className="btn-launch">
                  Connect Wallet
                </button>
              )
            }
          </div>
        </Offcanvas.Body>
      </Offcanvas>


      <Modal
        className="generic-modal stakingmodals howitworksmodal"
        show={show111}
        onHide={handleClose111}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Welcome to the GEMS Staking Program</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="howitworks-content ">
           <div className="forboxstyle">
           <h5>Here's how it works:</h5>
            <ul>
              <li>Start earning rewards as soon as you stake your tokens.</li>
              <li>You can unstake your tokens whenever you want, but they will only be released after a 7-day waiting period. Please note that rewards will stop accumulating once you request to unstake.</li>
              <li>Rewards can be claimed after a 90-day lockup period, which starts from the initial staking date.</li>
              <li>To compound your earnings, you can restake your rewards. However, this will initiate a new 90-day lockup period for the restaked rewards.</li>
            </ul>
            <h6>Important information about the Annual Percentage Yield (APY):</h6>
            <p className="para">The APY is dependent on the total number of tokens staked in the system and is subject to constant fluctuations. For instance, if the initially offered APY is 200%, it may decrease to 100% if the total amount of staked tokens doubles.</p>
            <p className="para"> If you have any further questions, please don't hesitate to reach out to our support team. Happy staking!</p>


           </div>

          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Navbar;
