import React from "react";
import useWindowDimensions from "../hooks/getDimensions";
// import mainloader from "../Assets/img/mainloader.svg";

function Loader({ text }) {
  const { width } = useWindowDimensions();

  return (
    <>
      <div className="">
        <div
          className="position-fixed w-100"
          style={{
            zIndex: 999999999,
            width: "100vw",
            height: "100vh",
            top: "-100px",
            left: "0",
            height: "135vh",
            background: "rgba(17, 33, 38, 0.65)",
            backdropFilter: "blur(7.5px)",
          }}
        >
          <div className="h-100 d-flex align-items-center justify-content-center">
            <div className="d-flex flex-wrap align-items-center justify-content-center flexdirectioncol">
              <img
                width={150}
                src="\assets\mainloader.svg"
                alt="loader"
              />
              <h2 className="loadertext">{text}</h2>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Loader;