import React from 'react';
import './footer.scss';

const Footer = () => {

    return (
        <div className="wrapper-footer">
            <section className="main-footer">
                <div className="custom-container">
                    <div className="parent">
                    <img src="\logo.svg" alt="img" className="img-fluid statcardimg hide-in-light-theme" />
                    <img src="\blacklogo.svg" alt="img" className="img-fluid d-none show-in-light-theme" />
                        <p className='right-reserved-text'>© 2024 GEMS, All Rights Reserved</p>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Footer;
