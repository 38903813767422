import React, { useState, useEffect } from "react";
import "./stats.scss";
import environment from "../../utils/Enviroment";
import { getStackContract } from "../../utils/contractHelpers";
import useWeb3 from "../../hooks/useWeb3";
import axios from "axios";
import { GetBalance } from "../../hooks/getBalance";
const Stats = ({ indexwaits, indexwait }) => {
  const web3 = useWeb3();
  var currencyFormatter = require("currency-formatter");
  const [MyDopBalance, setMyDopBalance] = useState("");
  const [Amountclamiednow, setAmountclamiednow] = useState();
  const [Amountclamiednows, setAmountclamiednows] = useState();
  const [getpooldata, setgetpooldata] = useState();
  const { GetBal } = GetBalance();
  const [balanceOf, setBalanceOf] = useState("");

  // console.log("samount styakers", Amountclamiednows)

  var rewardPerYear = 6428571;
  var apy = (rewardPerYear / MyDopBalance) * 100

  // console.log("value get",apy)

  const DopBalanceCheck = async () => {
    // console.log("total");
    const contractAddress = environment?.StackContact;
    const contract = getStackContract(contractAddress, web3);
    try {
      // console.log("total");
      const approved = await contract.methods.totalStaked().call();
      // let balance = parseInt(approved) / 1000000000000000000
      // console.log("balance", approved);
      setMyDopBalance(approved / 10 ** 18);
    } catch (error) {
      console.log("error get");
    }
    // setMainLoader(false);
  };

  const GetDopToken = async () => {
    // console.log("total");
    const contractAddress = environment?.StackContact;
    const contract = getStackContract(contractAddress, web3);
    try {
      // console.log("total");
      const approved = await contract.methods.rewardWallet().call();
      setgetpooldata(approved);
      // let balance = parseInt(approved) / 1000000000000000000
      // console.log("balance", approved);
      // setMyDopBalance(approved / 10 ** 18);
    } catch (error) {
      console.log("error get");
    }
    // setMainLoader(false);
  };

  const getBalance = async (getpooldata) => {
    let result = await GetBal(getpooldata);
    // let result2 = await GetHolder()
    // console.log("resulttvl", result2)
    setBalanceOf(result);
  };

  // console.log("statusss", balanceOf)


  const getClaimeduserdata = () => {
    var data = JSON.stringify({
      query: `query MyQuery {
                    aggregates(where: {id: "Total Stakers"}) {
                      amount
                    }
                  }`,
    });

    var config = {
      method: "post",
      url: environment.StackGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log("total stakers", response)
        setAmountclamiednows(response?.data?.data?.aggregates[0]?.amount);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const getClaimeduserdatas = () => {
    var data = JSON.stringify({
      query: `query MyQuery {
                    aggregates(where: {id: "Total Rewards Claimed"}) {
                      amount
                    }
                  }`,
    });

    var config = {
      method: "post",
      url: environment.StackGraph,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        // console.log("ammount total clamied", response);
        setAmountclamiednow(response?.data?.data?.aggregates[0]?.amount);
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  useEffect(() => {
    // console.log("error get");
    // console.log("out", indexwaits)

    // console.log("in")
    getClaimeduserdatas();
    getClaimeduserdata();
    DopBalanceCheck();
    GetDopToken();

  }, []);

  useEffect(() => {
    // console.log("error get");
    // console.log("out", indexwaits)
    if (getpooldata) {
      // console.log("in")
      getBalance(getpooldata);
    }
  }, [getpooldata]);

  return (
    <>
      <section className="statsmain">
        <div className="custom-container">
          {/* <h5 className="statshead">GEMS Staking Stats</h5> */}
          <div className="statscardtwo">
            <div className="statcard">
              {/* <img src="\assets\cardonemainimg.svg" alt="statcardimg" className="statcardmainimg" /> */}

              <img
                src="\assets\group1.png"
                alt="statcardimg"
                className="statcardimg hide-in-light-theme"
              />
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" className="d-none show-in-light-theme">
                <path d="M4.83872 15.0801L11.0339 15.0801L9.85814 12.7286L3.52734 12.7286L4.83872 15.0801Z" fill="#021C24" />
                <path d="M8.32133 15.0357L11.3042 20.4182L12.7685 18.2348L9.85881 12.7747L8.32133 15.0357Z" fill="#021C24" />
                <path d="M4.93012 15.1701L2.04593 20.417L0.581651 18.2335L2.44303 15.1701L4.93012 15.1701Z" fill="#021C24" />
                <path d="M14.5145 15.0801L20.7096 15.0801L19.5339 12.7286L13.2031 12.7286L14.5145 15.0801Z" fill="#021C24" />
                <path d="M17.9971 15.0357L20.98 20.4182L22.4443 18.2348L19.5346 12.7747L17.9971 15.0357Z" fill="#021C24" />
                <path d="M9.90122 5.3125L16.0964 5.3125L14.9206 2.96106L8.58984 2.96106L9.90122 5.3125Z" fill="#021C24" />
                <path d="M13.3819 5.26618L16.3648 10.6487L17.8291 8.46522L14.9194 3.00518L13.3819 5.26618Z" fill="#021C24" />
                <path d="M9.99067 5.40257L7.10648 10.6494L5.6422 8.46594L7.50357 5.40257L9.99067 5.40257Z" fill="#021C24" />
              </svg>
              <div className="parentsssss">
                <p className="statcardpara">GEMS Rewards Pool</p>
                <h6 className="statcardhead">
                  {currencyFormatter
                    .format(balanceOf, { code: "USD" })
                    .split(".")[0]
                    .slice(1, 100)}{" "}
                  GEMS
                </h6>
              </div>
            </div>
            <div className="statcard">
              {/* <img src="\assets\cardtwomainimg.svg" alt="statcardimg" className="statcardmainimg" /> */}
              <img
                src="\assets\group2.png"
                alt="statcardimg"
                className="statcardimg hide-in-light-theme"
              />
              <svg xmlns="http://www.w3.org/2000/svg" width="19" height="24" viewBox="0 0 19 24" fill="none" className="d-none show-in-light-theme">
                <path d="M13.7629 11.3024L13.7629 7.99219L8.71952 13.023L5.71289 10.9018L5.71289 13.9706L8.71952 16.2362L13.7629 11.3024Z" fill="#021C24" />
                <path d="M16.0957 6.93164L16.0957 4.172L9.40868 0.42866L9.40868 3.18831L16.0957 6.93164Z" fill="#021C24" />
                <path d="M2.70703 6.93164L2.70703 4.172L9.39406 0.428661L10.5501 2.16791L9.39406 3.18831L2.70703 6.93164Z" fill="#021C24" />
                <path d="M18.207 12.8418L18.207 6.92553L15.6853 6.92553L15.6853 12.8418L18.207 12.8418Z" fill="#021C24" />
                <path d="M0.554688 12.8418L0.554689 6.92553L3.07637 6.92553L3.07637 12.8418L0.554688 12.8418Z" fill="#021C24" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8.5724 20.6601C13.0443 19.0788 15.8188 16.119 15.685 12.3574L18.2066 12.4544C18.3964 17.788 14.3972 21.2751 9.4131 23.0376L8.5724 20.6601Z" fill="#021C24" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.2461 20.6601C5.7742 19.0788 2.9427 16.119 3.07652 12.3574L0.506325 12.4544C0.316585 17.788 4.42133 21.2751 9.40544 23.0376L10.2461 20.6601Z" fill="#021C24" />
              </svg>
              <div className="parentsssss">
                <p className="statcardpara">Total GEMS Staked</p>
                <h6 className="statcardhead"> {currencyFormatter
                  .format(MyDopBalance, { code: "USD" })
                  .split(".")[0]
                  .slice(1, 100)}{" "}
                  GEMS</h6>
              </div>
            </div>
            <div className="statcard">
              <img
                src="\assets\group3.png"
                alt="statcardimg"
                className="statcardimg hide-in-light-theme"
              />
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="24" viewBox="0 0 23 24" fill="none" className="d-none show-in-light-theme">
                <path d="M21.1641 22.3145L1.08413 22.3145L1.08413 19.9627L21.1641 19.9628L21.1641 22.3145Z" fill="#021C24" />
                <path d="M1.08594 22.3145L1.08594 11.6413L3.43764 10.0585L3.43764 22.3145L1.08594 22.3145Z" fill="#021C24" />
                <path d="M13.0063 14.3473L11.7352 12.2185L15.1694 6.2508L16.4405 8.37961L13.0063 14.3473Z" fill="#021C24" />
                <path d="M8.37103 18.0945L7.09995 15.9657L10.5342 9.99804L11.8053 12.1268L8.37103 18.0945Z" fill="#021C24" />
                <path d="M11.2451 2.5957H18.3907L17.0792 4.94741H9.93359L11.2451 2.5957Z" fill="#021C24" />
                <path d="M21.9673 8.78411L18.3945 2.59587L17.0136 4.90754L20.5864 11.0958L21.9673 8.78411Z" fill="#021C24" />
              </svg>
              <div className="parentsssss">
                <p className="statcardpara">Current APY</p>
                <h6 className="statcardhead">{apy && MyDopBalance ? parseFloat(apy).toFixed(2) : 23000.00}%</h6>
              </div>

            </div>
            <div className="statcard">
              <img
                src="\assets\group4.png"
                alt="statcardimg"
                className="statcardimg hide-in-light-theme"
              />
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none" className="d-none show-in-light-theme">
                <path d="M12.9645 20.6368C9.30935 22.7471 4.63546 21.4947 2.52513 17.8395C0.414804 14.1843 1.66717 9.51046 5.32237 7.40013C8.97756 5.2898 13.6514 6.54217 15.7618 10.1974C17.8721 13.8526 16.6197 18.5264 12.9645 20.6368Z" stroke="#021C24" stroke-width="2.04154" />
                <path d="M9.42188 3.54965C12.6779 0.864031 17.4945 1.3264 20.1801 4.58239C22.8657 7.83837 22.4033 12.655 19.1474 15.3406" stroke="#021C24" stroke-width="2.04154" />
                <rect x="9.09766" y="10.7148" width="4.79332" height="4.79332" transform="rotate(45 9.09766 10.7148)" fill="#021C24" />
              </svg>
              <div className="parentsssss">
                <p className="statcardpara">Total Rewards Earned</p>
                <h6 className="statcardhead">
                  {Amountclamiednow
                    ? parseFloat(Amountclamiednow / 10 ** 18).toFixed(3)
                    : 0}{" "}
                  GEMS
                </h6>
              </div>

            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Stats;
